









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'ErrorPage'
})
export default class ErrorPage extends Vue {
  @Prop({ default: '' }) private error!: string

  private get errorMessage(): string {
    switch (this.error) {
      case 'phone_number_error':
        return '既に使われている電話番号です。\nアプリをダウンロードしている方はそちらから診察予約をお願いいたします'
      default:
        return '正しい情報を入力してください'
    }
  }
}
